<template>
<div class="container-fluid e-bg-red-light-9">
  <!--TODO : BUILD FOR CYCLE 2  -- -->
  <div class="container">
    <div class="vh-100 row d-flex justify-content-center align-items-center no-gutters">
      <div class="col-md-8 col-xl-6 card">
        <div class="card-title border-bottom p-1">
          <img :src="systemLogo" alt="">
        </div>
        <form class="card-body" @submit.prevent="login">
          <div class="form-group form-row mb-1">
            <label for="Username" class="col-sm-2 col-md-3 col-form-label pt-1">Username</label>
            <div class="col-sm-10 col-md-9">
              <input type="text" class="form-control form-control-sm" id="Username"  placeholder="Enter Username" v-model="username" autocomplete="username">
              <span class="text-danger font-weight-bold" v-if="error.username">{{error.username.join(',')}}</span>
            </div>
          </div>
          <div class="form-group form-row">
            <label for="Password" class="col-sm-2 col-md-3 col-form-label pt-1">Password</label>
            <div class="col-sm-10 col-md-9 input-group mb-3">
              <input :type="fieldType" class="form-control form-control-sm" id="Password"  placeholder="Enter Password" v-model="password" autocomplete="current-password">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary btn-sm" type="button" @click="showPassword">
                  <font-awesome-icon :icon="fontIcon" class="e-text-white-hover" />
                </button>
              </div>
              <span class="text-danger col-md-12 pl-0  font-weight-bold" v-if="error.password">{{error.password.join(',')}}</span>

            </div>

          </div>
          <div class="form-group form-row mb-0 ">
            <div class="col-sm-12 text-center">
              <button type="submit" class="e-btn e-btn-blue e-text-white px-4">Login</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { vueAppMixin } from '../mixins/vueAppMixin'

export default {
  name: 'Login',
  data () {
    return {
      systemLogo: require('@/assets/kids_logo.png'),
      username: '',
      password: '',
      error:{},
      fieldType: 'password',
      fontIcon: 'eye-slash'
    }
  },
  mixins: [vueAppMixin],
  methods: {
    ...mapActions(['authenticateLogin']),
    async login () {
      const data = {
        username: this.username,
        password: this.password
      }
      const response=await this.authenticateLogin(data)
      if (typeof response === 'boolean' && response) {
        if (this?.user.user.vessel_id == null)
          this.$router.push({name: 'Dashboard'})
        else
          this.$router.push({name: 'MasterCiAir',query:{type:'air',t:new Date().getTime()}})
      }
      else {
        if([422,401].includes(response.status))this.error = response.data.errors;
        if(![422,401].includes(response.status) )this.error={}
      }
    },

    showPassword () {
      this.fieldType = this.fieldType === 'password' ? 'text' : 'password'
      this.fontIcon = this.fontIcon === 'eye-slash' ? 'eye' : 'eye-slash'
    }
  },
  computed: {
    ...mapGetters(['user'])
  }
}
</script>

<style scoped>

</style>
